<template>
  <v-card color="white" class="pa-8">
    <v-card-text class="px-0">
      <div>
        <div class="bsat__title">{{ $t("title") }}</div>

        <!-- **************************** OVERVIEW ********************************* -->
        <section id="overview">
          <div>
            <i18n
              path="overview.paragraph.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #link1>
                <a @click="goToTab(1)">{{ $t("overview.paragraph.link1") }}</a>
              </template>
              <template #link2>
                <a @click="goToTab(2)">{{ $t("overview.paragraph.link2") }}</a>
              </template>
            </i18n>
          </div>

          <div class="bsat__paragraph">{{ $t("overview.paragraph2") }}</div>
        </section>

        <!-- ************************ IMPLEMENTATION RECOMMENDATIONS ********************************* -->
        <section id="implementationRecommendation">
          <div class="bsat__subtitle mb-4">
            <strong>{{ $t("implementationRecommendations.title") }}</strong>
          </div>

          <ReadMore :intro="$t('implementationRecommendationReadMore')">
            <i18n
              path="implementationRecommendations.task.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.task.title")
                }}</strong>
              </template>
            </i18n>

            <div class="bsat__paragraph mb-4">
              <strong>{{
                $t("implementationRecommendations.taskLeader.title")
              }}</strong>
            </div>
            <ul>
              <li
                class="bsat__paragraph mb-4"
                v-for="(item, i) in taskLeaderList"
                :key="i"
              >
                {{ item }}
              </li>
            </ul>
            <div class="bsat__paragraph mb-4">
              <strong>{{ $t("proposedMethods.title") }}</strong>
            </div>

            <div class="bsat__paragraph">
              {{ $t("proposedMethods.paragraph") }}
            </div>

            <ul>
              <li
                class="bsat__paragraph mb-4"
                v-for="(p, i) in proposedMethods"
                :key="i"
                v-text="p"
              ></li>
            </ul>

            <div class="bsat__paragraph">
              {{ $t("proposedMethods.paragraph2") }}
            </div>
          </ReadMore>
        </section>

        <!-- ******************************** RECOMMENDED RESOURCES ******************************* -->
        <section id="recommendedResources">
          <div class="bsat__subtitle mb-4">
            {{ $t("recommendedResources") }}
          </div>

          <!-- <div class="bsat__paragraph mt-n3 mb-6">
            {{ $t("recommendedResourcesIntro") }}
          </div> -->

          <ul class="ml-0 ml-sm-4 bsat__ul">
            <li>
              <i18n
                path="resultsSwotAnalysis.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link>
                  <a @click="goToTab(1)">{{
                    $t("resultsSwotAnalysis.link")
                  }}</a>
                </template>
              </i18n>
            </li>

            <li>
              <div>
                <i18n
                  path="keyPerformance.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #title>
                    <strong>{{ $t("keyPerformance.title") }}</strong>
                  </template>
                </i18n>
                <a :href="keyPerformanceFile" target="_blank" download>
                  <v-btn
                    depressed
                    width="150"
                    height="26"
                    class="bsat__btn"
                    dark
                    color="dark"
                    >{{ $t("download") }}</v-btn
                  >
                </a>
              </div>
            </li>

            <li>
              <i18n
                path="bioBaseSolutions.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #title>
                  <strong>{{ $t("bioBaseSolutions.title") }}</strong>
                </template>
                <template #link>
                  <a :href="biobaseSolutionsLink">{{
                    $t("bioBaseSolutions.link")
                  }}</a>
                </template>
              </i18n>
              <a
                :href="biobaseSolutionsLink"
                target="_blank"
                rel="noopener noreferrer"
              >
                <v-btn
                  outlined
                  width="150"
                  height="26"
                  class="bsat__btn"
                  color="dark"
                  >{{ $t("visit") }}</v-btn
                >
              </a>
            </li>

            <li>
              <div>
                <i18n
                  path="bestPractices.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #title>
                    <strong>{{ $t("bestPractices.title") }}</strong>
                  </template>
                </i18n>
                <router-link
                  to="/best-practice-examples-of-bio-based-solutions-with-replicability-potential"
                >
                  <v-btn
                    outlined
                    width="150"
                    height="26"
                    class="bsat__btn mb-0"
                    color="dark"
                    >{{ $t("visit") }}</v-btn
                  >
                </router-link>
              </div>
            </li>

            <li>
              <i18n
                path="regionalPolicies.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link>
                  <strong>{{ $t("regionalPolicies.link") }}</strong>
                </template>
              </i18n>
              <v-btn
                outlined
                width="150"
                height="26"
                class="bsat__btn"
                color="dark"
                to="/repository-of-regional-policies-to-support-bio-based-models"
                >{{ $t("visit") }}</v-btn
              >
              <!-- <a
                :href="require('@/assets/files/financial_tools.docx').default"
                target="_blank"
                download
              >
                <v-btn
                  class="bsat__btn"
                  color="dark"
                  dark
                  depressed
                  width="150"
                  height="26"
                  >{{ $t("download") }}</v-btn
                >
              </a> -->
            </li>
          </ul>
        </section>
        <i18n
          class="mt-8"
          tag="div"
          path="downloadAll.text"
          style="font-family: Montserrat"
        >
          <template #link>
            <a class="dark-text mt-8" :href="zip" target="_blank" download>
              {{ $t("downloadAll.link") }}
            </a>
          </template>
        </i18n>
      </div>
    </v-card-text>
  </v-card>
</template>

<i18n>
{
  "en": {
    "bestPractices": {
      "text": "{title} Consult a selection of bio-based solutions from the POWER4BIO catalogue, which thoroughly describe their potential for replicability in rural areas",
      "title": "Best practice examples:"
    },
    "bioBaseSolutions": {
      "text": "{title} Get inspired by the POWER4BIO catalogue of bio-based solutions. In total, the catalogue contains 32 biorefineries, selected by their high market uptake potential and high technical readiness level (TRL) for bioenergy production, biomaterials, biochemicals as well as for food and feed. All factsheets include a short description and several characteristics of the solution, as well as information regarding the suitability of biomass feedstock for production of the target product, benefits of the solution related to the value chain and specific constraints for implementation. The selected content topics of the factsheets are General, Feedstock, Technology, Products, Environment and Impacts.",
      "title": "Catalogue of bio-based solutions:"
    },
    "implementationRecommendations": {
      "task": {
        "text": "{title}: Determine priority areas and their corresponding main goals. ",
        "title": "Task"
      },
      "taskLeader": {
        "title": "Task leader",
        "unorderedList": [
          "Strategy development working group. ",
          "Key stakeholders engaged through the participatory process. "
        ]
      },
      "title": "Implementation Recommendations"
    },
    "keyPerformance": {
      "text": "{title} The use of common indicators could reinforce the common understanding about the bioeconomy topic as well as frame the scope of the exercise. The following document could be an inspiring reference to the long list of indicators as part of the discussions to elaborate and dig into some of the 7 selected global indicators.",
      "title": "Key performance indicators:"
    },
    "overview": {
      "paragraph": {
        "link1": "(Phase 1)",
        "link2": "(Phase 2)",
        "text": "The regional analysis {link1} and co-created vision {link2} are the basis for setting the priority areas of the strategy. This will be done throughout the agreed participatory process (during step 1) with already engaged RBH actors. As highlighted in previous phases, it  should at this point include actors of all groups of interest for the strategy (policy, R&D+I, industry of interest for regional bioeconomy, academia, civil society, and investors)."
      },
      "paragraph2": "The participatory process can take a duration of several months and it will most certainly include a mix of the possible methods listed for this step. ",
      "paragraph3": "During the participatory process, taking into consideration the inputs and promoting an active discussion with engaged stakeholders about the TOWS analysis results will aid in setting the priority areas. These should be agreed as well by the involved Interministerial/interdepartmental group and experts from the advisory group. When defining the priority areas, their main goal should be clear and reveal how it relates to the achievement of the regional bioeconomy vision."
    },
    "proposedMethods": {
      "paragraph": "To consolidate and prioritise  areas of interest to your region, the following methods can be suitable:",
      "paragraph2": "All methods to be used should have been already planned in the participatory process approach (Step 1) as well as its regularity.",
      "title": "Proposed methods",
      "unorderedList": [
        "Sectorial focus groups ",
        "Discussion/workshop with a selected group of experts (Advisory group)",
        "Thematic working groups"
      ]
    },
    "recommendedResources": "Recommended resources",
    "regionalPolicies": {
      "link": "Repository of Supporting documents and tools for regional policies in the field of bioeconomy.",
      "text": "{link}: Identify areas of priority, based on supporting policies identified and good examples identified in POWER4BIO. Policy information from the bioeconomy field at this stage, from a helicopter view, will help the region assess the general elements to bear in mind in the following steps and phases."
    },
    "resultsSwotAnalysis": {
      "link": "(Phase 1)",
      "text": "Results of SWOTs analysis {link} and regional bioeconomy vision"
    },
    "title": "Step 2: Determine the priority areas and their corresponding main goals",
    "downloadAll": {
			"link": "click here",
			"text": "*To download all the materials of Phase 3 and Phase 4 as a zip file please {link}"
		}
  }
}
</i18n>

<script>
import ReadMore from "@/components/ReadMore";
import { EventBus } from "@/utils/EventBus";

export default {
  name: "Phase3Step2",
  components: {
    ReadMore
  },
  data() {
    return {
      keyPerformanceFile: require("@/assets/files/1_3_2_1_Key_performance_indicators_to_characterize_the_regional_bioeconomy.docx")
        .default,
      bestPractisesFile: require("@/assets/files/1_3_2_2_Best_practice_examples_of_bio_based_solutions_with_replicability_potential.docx")
        .default,
      biobaseSolutionsLink: "https://www.bio-based-solutions.eu/#/",
      zip: require("@/assets/files/Phase3_Phase4_common_resources.zip").default
    };
  },
  computed: {
    proposedMethods() {
      return Object.values(this.$t("proposedMethods.unorderedList"));
    },
    taskLeaderList() {
      return Object.values(
        this.$t("implementationRecommendations.taskLeader.unorderedList")
      );
    },
    resourcesUnorderedList() {
      return Object.values(this.$t("unorderedList"));
    }
  },
  methods: {
    goToTab(tab) {
      EventBus.$emit("goToTab", tab);
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/main";
</style>
